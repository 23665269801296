<template>
    <CContainer class="d-flex align-items-center min-vh-100">
        <CRow class="w-100 justify-content-center">
            <CCol col="11" sm="11" md="11" lg="8" xl="8">
                    <CCard class="p-4">
                        <CCardBody>
                            <CForm
                                    :was-validated="wasValidated"
                                    @submit.prevent="reset"
                                    novalidate
                            >
                                <h1>Відновлення пароля</h1>
                                <p class="text-muted">Введіть електронну адресу, яку було використано для реєстрації, та натисніть Відновити</p>
                                <CAlert v-if="success" show color="success">Інструкції по відновленню паролю відправленні вам на пошту</CAlert>
                                <CAlert v-if="failed" show color="danger">Будь ласка, спробуйте ще раз</CAlert>
                                <CInput
                                        placeholder="Email"
                                        prepend="@"
                                        required
                                        autocomplete="email"
                                        invalid-feedback="Введіть коректно email"
                                        @keyup.enter.native="reset"
                                        :is-valid="validateEmail"
                                        v-model="email"
                                >
                                </CInput>
                                <CRow>
                                    <CCol col="12" sm="7" md="8" lg="8" xl="9">
                                        <CButton
                                                color="link"
                                                to="/login"
                                        >
                                            Назад
                                        </CButton>
                                    </CCol>
                                    <CCol col="12" sm="5" md="4" lg="4" xl="3" class="text-right">
                                        <CButton
                                                v-if="loading"
                                                color="primary"
                                                class="btn-block "
                                                disabled

                                        >
                                            <div class="  align-items-center">
                                                <CSpinner color="default" size="sm"/>
                                            </div>
                                        </CButton>
                                        <CButton
                                                v-else
                                                color="primary"
                                                @click="reset"
                                                class="px-4 btn-block"
                                        >
                                            Відновити
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
    import axios from "axios";
    import {email, required} from "vuelidate/lib/validators";

    export default {
        name: "ResetPassword",
        data () {
            return {
                failed: false,
                success: false,
                loading: false,
                wasValidated: false,
                email: "",
            }
        },
        validations: {
            email: {
                required,
                email,
            },
        },
        methods:{
            validateEmail(email) {
                if (email === '') {
                    return
                }
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            reset(){
                if (this.$v.$invalid) {
                    this.wasValidated = true;
                    return;
                }
                let self = this;
                this.loading = true;
                axios.post(
                    '/api/account/password/reset',
                    {
                        email: self.email,
                    },)
                    .then(function(response){
                        self.loading = false;
                        if (response.status === 200){
                            self.success= true;
                            setTimeout(function(){
                                self.$router.push({path: `/login`})
                            }, 5000);
                            return;
                        }
                        self.failed = true;
                    })
                    .catch(function(){
                            self.loading = false;
                            self.failed = true;
                    });
            },
        }
    }
</script>
